<template>
	<div>
		<div class="buttons">
			<div>
				<Arrow @click.native="previousPage" type="left" color="#f72585" />
			</div>
			<div>
				<Arrow @click.native="nextPage" type="right" color="#f72585" />
			</div>
		</div>
		<swiper ref="swiperAllTags" class="swipe" :options="swiperOption">
			<swiper-slide v-for="(card, i) in cards" :key="i">
				<div class="tag-card" @click="(e) => goTo('Tag', { tag: card.slug }, e)">
					<div
						class="card gradient"
						:style="'background-image: url(' + card.taxonomy_and_category_image_data.medium_large + ')'"
					>
						<h3 class="font-size-14">
							{{ card.name }}
						</h3>
					</div>
				</div>
			</swiper-slide>
			<div class="swiper-pagination" slot="pagination"></div>
		</swiper>
	</div>
</template>

<script>
import Arrow from '@/components/ui/Arrow.vue';

export default {
	name: 'AllTags',
	props: ['block', 'blocks', 'parentBlock'],
	components: { Arrow },
	data() {
		return {
			cards: [],
			swiperOption: {
				spaceBetween: 60,
				pagination: {
					el: '.swiper-pagination',
					clickable: false,
				},
				breakpoints: {
					0: {
						slidesPerView: 1,
					},
					640: {
						slidesPerView: 2,
					},
					1024: {
						slidesPerView: 4,
					},
				},
			},
		};
	},
	async mounted() {
		let response = await this.$api.get('/wp/v2/tags');
		this.cards = response.data;
		this.cards.map((card) => {
			card.image = !card.taxonomy_and_category_image_data.error
				? card.taxonomy_and_category_image_data.large[0]
				: card.taxonomy_and_category_image_data.error.rect;
		});
	},
	computed: {
		swiper() {
			return this.$refs.swiperAllTags.$swiper;
		},
	},
	methods: {
		nextPage() {
			this.swiper.slideNext();
		},
		previousPage() {
			this.swiper.slidePrev();
		},
		goto(slug) {
			this.$router
				.push({
					name: 'Tags',
					params: {
						slug: slug,
					},
				})
				.catch(() => {});
		},
	},
};
</script>

<style lang="scss" scoped>
.buttons {
	position: absolute;
	@media (max-width: $tablet-m) {
		display: none;
	}
}
.card {
	height: 400px;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	cursor: pointer;
	padding: 0 $spacing-0/3 * 2;
}
.gradient:before {
	content: '';
	background: linear-gradient(0deg, #000 0%, rgba(0, 212, 255, 0) 100%);
	position: absolute;
	top: 0;
	left: $spacing-0/3 * 2;
	right: $spacing-0/3 * 2;
	bottom: 0;
}
h3 {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #fff;
}

.tag-card {
	position: relative;
	margin-bottom: 40px;
	width: 100%;
	padding: 0 $spacing-0/3 * 2;
}
</style>

<style lang="scss">
.swipe {
	width: calc(100% - 60px);
	margin-left: 60px;

	@media (max-width: $tablet-m) {
		margin: auto;
		width: 100%;
	}
	//Class inside swiper need to be declared outside of scoped
	.swiper-pagination-bullet-active {
		background-color: $aili-pink;
	}
}
</style>
