import { render, staticRenderFns } from "./lazyblock_last_recipes_by_category.vue?vue&type=template&id=5e5acd2f&scoped=true&"
import script from "./lazyblock_last_recipes_by_category.vue?vue&type=script&lang=js&"
export * from "./lazyblock_last_recipes_by_category.vue?vue&type=script&lang=js&"
import style0 from "./lazyblock_last_recipes_by_category.vue?vue&type=style&index=0&id=5e5acd2f&lang=scss&scoped=true&"
import style1 from "./lazyblock_last_recipes_by_category.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e5acd2f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VLazy } from 'vuetify/lib/components/VLazy';
installComponents(component, {VLazy})
