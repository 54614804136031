<template>
	<v-lazy transition="fade-transition">
		<div class="last-recipes-by-category">
			<div class="buttons">
				<div>
					<Arrow @click.native="previousPage" type="left" color="#f72585" />
				</div>
				<div>
					<Arrow @click.native="nextPage" type="right" color="#f72585" />
				</div>
			</div>
			<div class="last-recipes-by-category-swiper">
				<swiper @slideChange="onSlideChange" ref="swiperRecipeCategories" :options="swiperOption">
					<swiper-slide v-for="(card, i) in cards" :key="i">
						<div class="link card">
							<CardSingleRecipe
								:slug="card.slug"
								:image="card.featured_media_data.medium"
								:title="card.title.rendered"
								:author="card.author_data"
								:preview_data="card.preview_data"
								:categories="card['recipe-category_data']"
							/>
						</div>
					</swiper-slide>
					<div class="swiper-pagination" slot="pagination"></div>
				</swiper>
			</div>
		</div>
	</v-lazy>
</template>

<script>
import Arrow from '@/components/ui/Arrow.vue';
import CardSingleRecipe from '@/components/ui/CardSingleRecipe.vue';

export default {
	name: 'AllTags',
	props: ['block', 'blocks', 'parentBlock', 'category', 'color'],
	components: {
		CardSingleRecipe,
		Arrow,
	},
	data() {
		return {
			currentIndex: 0,
			cards: [],
			swiperOption: {
				slidesPerView: 1,
				centeredSlides: false,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				breakpoints: {
					0: {
						slidesPerView: 1,
					},
					640: {
						slidesPerView: 2,
					},
					1024: {
						slidesPerView: 3,
					},
				},
			},
		};
	},
	mounted() {
		if (this.category) {
			this.$api
				.get('/wp/v2/recipe', {
					params: {
						'recipe-category-slug': this.category,
					},
				})
				.then((response) => {
					this.cards = response.data;
					this.cards.map((card) => {
						card.image = !card.featured_media_data.error
							? card.featured_media_data.large
							: card.featured_media_data.error.rect;
					});
				})
				.catch((error) => {
					this.get404(error);
				});
		}
	},
	computed: {
		swiper() {
			return this.$refs.swiperRecipeCategories.$swiper;
		},
	},
	methods: {
		onSlideChange() {
			this.currentIndex = this.swiper.activeIndex;
		},
		changeUrl(url) {
			window.location.href = url;
		},
		onPaginationClick(index) {
			this.swiper.slideTo(index);
		},
		nextPage() {
			this.swiper.slideNext();
		},
		prevPage() {
			this.swiper.slidePrev();
		},
	},
};
</script>

<style lang="scss" scoped>
.card {
	margin-bottom: 40px;
	min-height: 400px;
	width: 100%;
	box-sizing: border-box;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	cursor: pointer;
	text-decoration: none;

	padding: 0 $spacing-0/3 * 2;

	.image-card {
		width: 100%;
		height: 200px;
		padding: 0;
		background-size: cover;
		background-position: center;
	}
	.content-card {
		padding: $spacing-0;
		position: relative;
		min-height: 150px;
	}
	h3 {
		margin: 0;
		margin-bottom: $spacing-0;
	}
	h4 {
		position: absolute;
		bottom: $spacing-0;
	}
}

.link {
	text-decoration: none;
	display: inline-block;
	* {
		text-align: left;
	}
	@media (max-width: $tablet-l) {
		text-align: center;
	}
}

h3 {
	margin-top: $spacing-1;
}

.gradient:before {
	content: '';
	background: linear-gradient(0deg, #000 0%, rgba(0, 212, 255, 0) 100%);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.buttons {
	position: absolute;
	@media (max-width: $tablet-m) {
		display: none;
	}
}
.pagination {
	display: none;
	@media (max-width: $tablet-m) {
		display: block;
	}
}
.swiper-wrapper {
	margin-bottom: $spacing-1 !important;
	margin-right: $spacing-0 !important;
}
</style>

<style lang="scss">
.last-recipes-by-category-swiper {
	width: calc(100% - 60px);
	margin-left: 60px;

	@media (max-width: $tablet-m) {
		margin: auto;
		width: 100%;
	}
	//Class inside swiper need to be declared outside of scoped
	.swiper-pagination-bullet-active {
		background-color: $aili-pink;
	}
}
</style>
