<template>
	<v-container>
		<header class="panel-header" v-if="$route.name != 'Recipes'">
			<div class="title-wrap">
				<h2 class="font-size-35">
					Ricette
				</h2>
			</div>
			<div class="button-wrap">
				<Button color="aili-pink" :inverted="$route.name != 'Recipes'" href="ricette" value="Scopri" />
			</div>
		</header>
		<div
			v-if="$vuetify.breakpoint.width > 1024"
			:class="{
				posts: true,
				'one-row': posts ? posts.length <= 5 : false,
			}"
		>
			<a
				v-for="(post, i) in posts"
				:key="i"
				@click="goTo('Recipe', { slug: post.post_name })"
				:class="{
					['element-' + i]: true,
					post: true,
					'background-aili-purple-light': parentBlock.attrs.background == '#fff',
				}"
				:href="'ricetta/' + post.post_name"
				:title="post.post_title"
			>
				<div
					:class="{
						gradient: !post.featured_media_data.error,
						'has-image': !post.featured_media_data.error,
					}"
				>
					<div
						class="post-image"
						:style="{
							'background-image': 'url(' + post.featured_media_data.large + ')',
						}"
					></div>
				</div>
				<v-card-text
					:class="
						'pa-0 ' +
							'color-' +
							(!post.featured_media_data.error ? 'aili-white' : parentBlock.attrs.background)
					"
				>
					<div class="description color-aili-pink">
						<div class="categories tiny">
							<div class="post-type">Ricette</div>

							<div class="separator"></div>
							<div class="category" v-for="(category, j) in post.categories" :key="j">
								<span class="category-name">{{ category.name }}</span>
								<span class="category-comma" v-if="j < post.categories.length - 1">,</span>
							</div>
						</div>
						<h3
							:class="{
								'font-size-35': i == 0,
								'font-size-20': i > 0,
								'color-aili-white': true,
							}"
							v-html="post.post_title"
						/>
					</div>
				</v-card-text>
			</a>
		</div>
		<swiper
			v-else
			ref="swiperLastRecipes"
			class="swiper-last-recipes"
			@slideChange="onSlideChange"
			:options="swiperOption"
		>
			<swiper-slide v-for="(post, i) in posts" :key="i">
				<v-card tile class="post" :href="'ricetta/' + post.post_name">
					<div
						:class="{
							gradient: !post.featured_media_data.error,
							'has-image': !post.featured_media_data.error,
						}"
					>
						<div
							class="post-image"
							:style="{
								'background-image': 'url(' + post.featured_media_data.large + ')',
							}"
						></div>
						<v-card-text
							:class="
								'pa-0 ' +
									'color-' +
									(!post.featured_media_data.error ? 'aili-white' : parentBlock.attrs.background)
							"
						>
							<div class="description">
								<div class="categories tiny">
									<div class="post-type">Ricette</div>

									<div class="separator"></div>
									<div class="category" v-for="(category, j) in post.categories" :key="j">
										<span class="category-name">{{ category.name }}</span>
										<span class="category-comma" v-if="j < post.categories.length - 1">,</span>
									</div>
								</div>
								<h3>{{ post.post_title }}</h3>
							</div>
						</v-card-text>
					</div>
				</v-card>
			</swiper-slide>
			<div v-if="$vuetify.breakpoint.width < 1024" class="swiper-pagination" slot="pagination"></div>
		</swiper>
	</v-container>
</template>

<script>
export default {
	name: 'LastRecipes',
	props: ['block', 'parentBlock'],
	data() {
		return {
			currentIndex: 0,
			posts: null,
			swiperOption: {
				slidesPerView: 1,
				spaceBetween: 60,
				centeredSlides: false,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				breakpoints: {
					768: {
						slidesPerView: 2,
						spaceBetween: 20,
					},
				},
			},
		};
	},
	methods: {
		onSlideChange() {
			this.currentIndex = this.swiper.activeIndex;
		},
		changeUrl(url) {
			window.location.href = url;
		},
		onPaginationClick(index) {
			this.swiper.slideTo(index);
		},
		nextPage() {
			this.swiper.slideNext();
		},
		prevPage() {
			this.swiper.slidePrev();
		},
	},
	computed: {
		swiper() {
			return this.$refs.swiperLastRecipes.$swiper;
		},
	},
	async mounted() {
		let stickyRecipes = await this.$api.get('/qzr-data/sticky/recipe');
		this.posts = stickyRecipes.data;
		this.posts.map((post) => {
			post.categories = post['recipe-category_data'];
		});
	},
};
</script>

<style lang="scss" scoped>
#app {
	.panel-header {
		margin-bottom: $spacing-1;
		@media (max-width: 768px) {
			margin-bottom: $spacing-0;
		}
		h2 {
			margin: 0;
		}
		.title-wrap {
			width: calc(100% - 100px);
			display: inline-block;
		}

		.button-wrap {
			width: 100px;
			text-align: right;
			display: inline-block;
		}
	}
	.categories {
		line-height: 1.3;
		font-size: 12px;
		text-transform: uppercase;
		color: #f53b90;
	}

	.category {
		margin: 0;
	}

	.description {
		position: absolute;
		bottom: $spacing-0;
		left: $spacing-0;
		z-index: 10;
	}

	.element-0 {
		grid-row: 1 / span 1;
		grid-column: 1 / span 4;
	}

	.element-1 {
		grid-row: 2 / span 1;
		grid-column: 1 / span 2;
	}

	.element-2 {
		grid-row: 2 / span 1;
		grid-column: 3 / span 2;
	}

	h3 {
		margin: 0;
	}

	.posts {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(1, 1fr);
		column-gap: 15px;
		row-gap: 15px;

		@media (max-width: $tablet-m) {
			display: inline-block;
		}
	}

	.post {
		display: inline-block;
		position: relative;
		width: 100%;
		cursor: pointer;

		@media (max-width: $tablet-m) {
			display: inline-block;
			margin-bottom: 25px;
		}
	}

	.post.has-background-image:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 1;
	}

	.post-image {
		z-index: -1;
		height: 100%;
		padding-top: 50%;
		background-size: cover;

		@media (max-width: $tablet-l) {
			padding-top: 80%;
		}
	}

	.gradient.has-image:before {
		content: '';
		background: linear-gradient(0deg, #000 0%, rgba(0, 212, 255, 0) 100%);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.swiper-container-horizontal > .swiper-pagination-bullets {
		bottom: 0px;
		left: 0;
		width: 100%;
	}
}
</style>

<style lang="scss">
.swiper-last-recipes {
	.swiper-pagination-bullet-active {
		background-color: $aili-pink-light;
	}
}
</style>
